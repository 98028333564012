import {
    Box,
    Heading,
    Text,
  } from '@chakra-ui/react';
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <Box w={'95vw'} m={'auto'}>
        <Heading
            fontSize={{
                base: '4xl',
                md: '5xl'
            }}
            textAlign='center'
            mb={'30px'}
            mt={'20px'}
            color="#7d6b57"
        >
            Privacy & Policy
        </Heading>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Introduction
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            Welcome to Carrom Clash, a casual carrom board game developed by Pixcel Craft. At Carrom Clash, we hold your privacy in high regard and are dedicated to safeguarding your personal information. This Privacy Policy serves to elucidate how we gather, utilize, disclose, and safeguard your information when you engage with our game. Rest assured, your privacy is of utmost importance to us, and we are committed to ensuring the security and confidentiality of your data throughout your gaming experience.
        </Text>
          <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Information We Collect
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Google Login:</b> If you choose to log in through Google, Carrom Clash may collect your Google account information, including your email address and profile picture. This information is obtained to facilitate your login process and personalize your experience within the game.          
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Guest Account:</b> Alternatively, you have the option to use a guest account. In this case, Carrom Clash will collect only the minimal information necessary to establish a guest account. This likely includes basic account identifiers such as a username or unique identifier.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            By providing these options, Carrom Clash aims to accommodate users' preferences regarding account creation while ensuring a seamless gaming experience. We recommend you to login to save progress.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            By offering the choice between Google login and a guest account, Carrom Clash enables users to personalize their gaming experience. Opting for Google login allows users to seamlessly access their account, save progress, and enjoy personalized features based on their email address and profile picture. Alternatively, users can opt for a guest account, providing only essential information for account creation. Regardless of the choice, Carrom Clash prioritizes user convenience and a smooth gaming journey, encouraging users to log in to enjoy the benefits of progress saving.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To provide and improve our game and its features: We utilize the information collected to enhance your experience with Carrom Clash. This includes optimizing gameplay mechanics, refining graphics and animations, and introducing new features based on player feedback.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            Information regarding your use of Carrom Clash includes various data points:
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           1. <b>Game ID:</b> A unique identifier assigned to each player within Carrom Clash.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           2. <b>Install and uninstall timestamps:</b> Timestamps indicating when the game was installed and uninstalled on your device.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           3. <b>Date and time stamps of game events:</b> Records of specific events within the game, including when they occurred.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           4. <b>Game time:</b> The duration of time spent playing Carrom Clash.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           5. <b>Achieved scores:</b> Scores attained by players during gameplay.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           6. <b>Interactions with our teams:</b> Instances where players engage with our support or development teams for assistance or feedback.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           7. <b>Advertising and promotions engagement:</b> Data related to interactions with in-game advertisements and promotional content.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           8. <b>Transaction records:</b> Information pertaining to in-game purchases or transactions.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           9. <b>Evidence of using cheating software or modified versions of the game:</b> Any data indicating the use of unauthorized software or alterations to the game that violate our terms of service.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            These data points are collected to enhance the gaming experience, provide support, and ensure fair play within Carrom Clash. We handle this information with utmost care and in accordance with our privacy policy.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>a. Evidence of using cheating software or modified versions of the game?</b><br/> Cookies are small pieces of data stored on your device when you access or use the Game. They are commonly used to enhance user experience, personalize content, and gather information about user preferences and behaviors.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            Types of Cookies We Use
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Essential Cookies:</b> These cookies are necessary for the functioning of the Game and cannot be disabled. They include cookies that enable basic functions like navigation and access to secure areas of the Game.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Analytics Cookies:</b> We use analytics cookies to gather information about how users interact with the Game. This helps us analyze and improve the performance, features, and user experience of the Game.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Advertising Cookies:</b> Advertising cookies are used to deliver personalized advertisements to users based on their interests and browsing behavior. These cookies may be used by third-party advertisers to serve ads relevant to your preferences.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            By accessing or using the Game, you consent to the use of cookies as described in this policy
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>b. Analytics:</b><br/> In our efforts to continually refine and optimize the gaming experience for our users, we've integrated Firebase Analytics and Crashlytics into our platform. Firebase Analytics provides us with invaluable insights into how users interact with our game. This includes a wide range of metrics such as player actions, engagement levels, feature usage, and more. By analyzing this data, we gain a deep understanding of player behavior, preferences, and trends. Additionally, Crashlytics plays a crucial role in ensuring the stability and reliability of our game. It helps us track and monitor any instances of crashes or bugs that users may encounter while playing. When a crash or bug occurs, Crashlytics promptly notifies us, providing detailed reports on the nature of the issue, the device and OS version involved, and other relevant information. This allows our development team to quickly diagnose and address the root cause of the problem, preventing further disruptions to the gaming experience. By leveraging Firebase Analytics and Crashlytics together, we have a comprehensive toolkit for continuously improving our game. We can identify areas of the game that need enhancement, prioritize feature updates based on user feedback and behavior, and swiftly resolve any technical issues that may arise. Ultimately, our goal is to deliver a seamless, engaging, and enjoyable gaming experience for all our users.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>c. Chat feature:</b><br/> Carrom Clash offers chat communication features to enable players to interact and engage with each other while playing the game. It's important for users to understand that when they utilize these chat features, any content they share, whether it's text-based messages or voice communications, may be collected and stored by us. The purpose of collecting and storing this information is twofold:
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Facilitating Interactions:</b> We collect and store chat content to facilitate smooth interactions between players. This includes ensuring that messages are delivered accurately and in a timely manner, as well as providing features such as message history for users to refer back to previous conversations.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Enforcement of Terms of Service:</b> Additionally, we reserve the right to monitor chat content to ensure compliance with our terms of service. This includes identifying and addressing any violations such as harassment, hate speech, or other inappropriate behavior within the chat feature. In such cases, the collected chat data may be used as evidence during investigations or enforcement actions.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            It's important for users to be aware that while we take measures to protect the privacy and security of their communications, there is a possibility that their chat content may be accessed by us for the purposes outlined above. We encourage users to familiarize themselves with our terms of service and community guidelines to ensure a positive and respectful gaming environment for all players.
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            How We Use Your Information
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To provide and improve our game and its features: We utilize the information collected to enhance your experience with Carrom Clash. This includes optimizing gameplay mechanics, refining graphics and animations, and introducing new features based on player feedback.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To personalize your gaming experience and provide customer support: Your information helps us tailor the game to your preferences, offering personalized challenges, rewards, and recommendations. Additionally, it enables us to provide efficient and effective customer support, addressing any queries or issues you may encounter while playing.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To communicate with you regarding game updates and promotions: We use your information to keep you informed about the latest updates, features, and promotions within Carrom Clash. This ensures that you're always up-to-date with the latest developments and opportunities to enhance your gaming experience
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To address any technical or security issues: Your information is essential for identifying and resolving technical glitches or security vulnerabilities within the game. By promptly addressing such issues, we ensure a smooth and secure gaming environment for all players.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To enforce our terms of service and maintain a safe gaming environment: We employ your information to uphold our terms of service and community guidelines, fostering a safe and respectful gaming community. This includes monitoring for any violations of the terms of service, such as cheating or inappropriate behavior, and taking appropriate action to maintain a positive gaming experience for all users.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            By leveraging the information collected for these purposes, Carrom Clash strives to deliver an engaging, personalized, and secure gaming experience for players of all skill levels.

        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Sharing Your Information    
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            With third-party service providers: We may share your information with trusted third-party service providers who assist us in delivering and maintaining Carrom Clash. These providers may include hosting services, analytics platforms, customer support tools, and payment processors. We ensure that these third parties adhere to strict confidentiality and security standards to safeguard your information. With third-party service providers: We may share your information with trusted third-party service providers who assist us in delivering and maintaining Carrom Clash. These providers may include hosting services, analytics platforms, customer support tools, and payment processors. We ensure that these third parties adhere to strict confidentiality and security standards to safeguard your information.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To comply with legal obligations and protect rights: In certain situations, we may be required to share your information to comply with applicable laws, regulations, or legal processes. This may include responding to lawful requests from government authorities, enforcing our terms of service, or protecting our rights, privacy, safety, or property, as well as those of our users and the general public.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            In connection with a business transfer: In the event of a merger, acquisition, or sale of our company or its assets, user information may be transferred as part of the transaction. We will ensure that any such transfer is conducted in compliance with applicable data protection laws and that your information continues to be protected in accordance with this privacy policy.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            It's important to note that while we may share your information in the circumstances outlined above, we do not sell your personal information to third parties. We prioritize the privacy and security of our users' information and take measures to ensure that it is handled responsibly and in accordance with applicable laws and regulations.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
           <b>THIRD PARTY PRIVACY PRACTICES:</b> If you access Carrom Clash through a third-party platform such as Facebook or Google ("Third-Party Services"), it's essential to recognize that these platforms may gather additional information about you. This may include data you directly share with them or details about your usage of Carrom Clash, all in accordance with their respective terms and privacy policies. It's important to note that the privacy practices outlined in our policy do not extend to Third-Party Services. Any links within Carrom Clash leading to Third-Party Services are provided for convenience and do not imply our endorsement or review of those services.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            To comply with legal obligations and protect rights: In certain situations, we may be required to share your information to comply with applicable laws, regulations, or legal processes. This may include responding to lawful requests from government authorities, enforcing our terms of service, or protecting our rights, privacy, safety, or property, as well as those of our users and the general public.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            In connection with a business transfer: In the event of a merger, acquisition, or sale of our company or its assets, user information may be transferred as part of the transaction. We will ensure that any such transfer is conducted in compliance with applicable data protection laws and that your information continues to be protected in accordance with this privacy policy.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            It's important to note that while we may share your information in the circumstances outlined above, we do not sell your personal information to third parties. We prioritize the privacy and security of our users' information and take measures to ensure that it is handled responsibly and in accordance with applicable laws and regulations.

        </Text>

        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Data Security
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            Ensuring the security of your data is a top priority for us. We employ industry-standard security measures to protect the information you share with us while playing Carrom Clash. These measures include encryption, firewalls, and other advanced techniques designed to safeguard your data from unauthorized access, disclosure, alteration, or destruction.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            However, it's important to understand that despite our best efforts, no method of transmission or storage over the internet can be guaranteed to be completely secure. Cybersecurity threats are constantly evolving, and there's always a risk of unauthorized access or breaches, despite our stringent security protocols.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'10px'}
          >
            Therefore, while we take every possible precaution to protect your data, we want to be transparent about the inherent risks involved in using any online service, including our game. By acknowledging that no system is entirely infallible, we aim to empower users to make informed decisions about their online activities.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            Ultimately, your decision to use our Carrom game is made with an understanding of these risks, and we encourage you to take appropriate precautions to protect your personal information, such as using strong, unique passwords and being cautious about sharing sensitive information online.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            By providing this detailed explanation, we aim to demonstrate our commitment to transparency and user security, while also emphasizing the shared responsibility we have in safeguarding your data.

        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Your Choices and Rights
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Your Rights:</b> If you wish to exercise any of the rights described below, you can contact us anytime via email at carromclash.helpdesk@gmail.com. We are committed to processing and responding to your requests promptly, typically within one month of receiving your request. However, if your request is complex, it may take up to three months, as permitted by the GDPR.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Right to Access:</b> You have the right to request access to the data we process about you. This includes information about the purposes of processing, the categories of personal data involved, recipients or categories of recipients, the anticipated duration of data storage, and the right to obtain a copy of your personal data undergoing processing. Please note that access may be restricted for intellectual property or trade secret reasons.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Right to Object:</b> You have the right to object to the processing of your personal data, particularly when processing is based on the GDPR's balancing-of-interest rule. We will cease processing unless there are compelling legitimate grounds that override your interests, rights, and freedoms, or if processing is necessary for legal claims. You can also object to processing for direct marketing purposes at any time, which will result in the cessation of such processing. Please note that objecting to direct marketing may result in the automatic cessation of your user license for the app.


        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Right to Rectification and Erasure:</b> You have the right to have inaccurate personal data rectified and to have your personal data erased under certain circumstances. These circumstances include when data are no longer necessary, consent is withdrawn, objections are made without overriding legitimate grounds, or data are unlawfully processed. However, your right to erasure may be limited if data are required for legal obligations or legal claims.

        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Right to Restriction:</b> You have the right to restrict processing in certain circumstances, where processing will only occur with your consent, for legal claims, or to protect vital public interests.


        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
           <b>Right to Delete Your Data:</b> You have the right to withdraw consent and request the deletion of your data at any time. Contact us at carromclash.helpdesk@gmail.com.com for assistance with this process. Once your account is deleted, reactivation or retrieval of content will not be possible. The withdrawal of consent does not affect prior lawful processing based on your consent.


        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
           <b>Acceptance of Terms:</b> By using Carrom Clash services and consenting to our policies, you agree to the terms and conditions of our Privacy Policy. This includes consenting to the collection, use, and disclosure of your information in accordance with the policy terms. If you do not agree to these terms and conditions, please refrain from using our service.


        </Text>

        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Children's Privacy
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            Carrom Clash is designed for players aged 13 and above. If you are between 13 and 18 years old, please obtain consent from your parent or guardian before using the game. We do not permit individuals under the age of 13 to use Carrom Clash. If it comes to our attention that we have inadvertently collected personal information from a child under 13, we will take appropriate action, including deleting the account. It's important to us that Carrom Clash is used responsibly and in compliance with applicable age restrictions.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Changes to this Privacy Policy
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            We reserve the right to update this Privacy Policy periodically to align with changes in our practices or for other operational, legal, or regulatory reasons. Any significant updates will be communicated to you through notifications within the Carrom Clash game or via email. It's important to stay informed about any modifications to our Privacy Policy, so we encourage you to review it periodically. Your continued use of Carrom Clash after any updates signifies your acceptance of the revised Privacy Policy.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Contact Us
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            If you have any questions, concerns, or requests related to this Privacy Policy, please contact us at <a href='mailto:carromclash.helpdesk@gmail.com' style={{color:'blue'}}>carromclash.helpdesk@gmail.com</a>.
            
        </Text>
        
    </Box>
  )
}

export default PrivacyPolicy