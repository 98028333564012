import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Flex,
  HStack,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  useColorMode,
  Image
} from "@chakra-ui/react"
import { FaInstagram, FaYoutube, FaFacebookF } from "react-icons/fa"
import { TbBrandTelegram } from "react-icons/tb";
// import { FaXTwitter } from "react-icons/fa6";
// import { BiMailSend } from "react-icons/bi"
import app_icon from "../assets/images/app_icon.png"
import app_name from "../assets/images/app_name _text.png"


<Flex>
              <HStack>
                <Image 
                  boxSize='50px'
                  objectFit='cover'
                  src={app_icon} 
                  alt="logo" 
                />
                <Image 
                  boxSize='50px'
                  w="auto"
                  objectFit='cover'
                  src={app_name} 
                  alt="name" 
                />
              </HStack>
  </Flex>

const SocialButton = ({ children, label, href }) => {

  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200")
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  )
}

export default function Footer() {
    
  const {colorMode} = useColorMode();

  return (
    <Box
      mt={10}
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      pos='relative' bottom='0px'
      background={colorMode === 'light'? 'gray.200': 'rgba(17, 25, 40, 0.75)'}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6} align={"center"}>
            <Box>
            <Flex>
              <HStack>
                <Image 
                  boxSize='50px'
                  objectFit='cover'
                  src={app_icon} 
                  alt="logo" 
                />
                <Image 
                  boxSize='50px'
                  w="auto"
                  objectFit='cover'
                  src={app_name} 
                  alt="name" 
                />
              </HStack>
            </Flex>
            </Box>
            {/* <Text fontSize={"sm"}>
              © 2024 Zakir Siddiqui. All rights reserved
            </Text> */}
            <Stack direction={"row"} spacing={6}>
              <SocialButton label={"Facebook"} href={"https://www.facebook.com/share/bNRPEkoYpi6A4YXz/?mibextid=qi2Omg"}>
                <FaFacebookF />
              </SocialButton>
              <SocialButton label={"Telegram"} href={"https://t.me/carrom_cash"}>
                <TbBrandTelegram />
              </SocialButton>
              <SocialButton label={"Instagram"} href={"https://www.instagram.com/carrom_clash"}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          {/* <Stack direction={"row"} justifyContent={"space-around"}> */}
          <Stack align={"center"}>
            <ListHeader>Company</ListHeader>
            <Box as="a" href={"/About"}>
              About us
            </Box>
            {/* <Box as="a" href={"#"}>
              Blog
            </Box> */}
            <Box as="a" href={"/Contact"}>
              Contact us
            </Box>
            {/* <Box as="a" href={"#"}>
              Pricing
            </Box> */}
            {/* <Box as="a" href={"#"}>
              Testimonials
            </Box> */}
          </Stack>

          <Stack align={"center"}>
            <ListHeader>Support</ListHeader>
            {/* <Box as="a" href={"#"}>
              Help Center
            </Box> */}
            <Box as="a" href={"/TermsCondition"}>
              Terms and Condition
            </Box>
            {/* <Box as="a" href={"#"}>
              Legal
            </Box> */}
            <Box as="a" href={"/PrivacyPolicy"}>
              Privacy Policy
            </Box>
            {/* <Box as="a" href={"#"}>
              Satus
            </Box> */}
          </Stack>
          {/* </Stack> */}
          <Stack alignSelf={"center"} align={"center"}>
            <Text fontSize={"sm"}>
              © 2024 W3N Solution. All rights reserved
            </Text>
            {/* <ListHeader>Stay up to date</ListHeader>
            <Stack direction={"row"}>
              <Input
                placeholder={"Your email address"}
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300"
                }}
              />
              <IconButton
                bg={useColorModeValue("green.400", "green.800")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "green.600"
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
              /> */}
            {/* </Stack> */}
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  )
}
