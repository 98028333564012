import {
    Box,
    Heading,
    Text,
  } from '@chakra-ui/react';
import React from 'react'

const AboutContent = () => {
  return (
    <Box w={'95vw'} m={'auto'}>
        <Heading
            fontSize={{
                base: '4xl',
                md: '5xl'
            }}
            textAlign='center'
            mb={'30px'}
            mt={'20px'}
            color="#7d6b57"
        >
            About Us
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            <b>Welcome to PixelCraft</b> — where innovation, creativity, and passion meet to create extraordinary gaming experiences. Based in the heart of the digital entertainment industry, PixelCraft is a pioneering video game development company dedicated to bringing engaging, fun, and innovative games to players around the world.
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Our Journey
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            Founded by a group of passionate gamers and seasoned developers, PixelCraft has always been driven by a single mission: to develop games that entertain, challenge, and inspire. We believe in the power of play and the importance of creating connections through games, which has been our guiding star since our inception.
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Our Game
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            Our latest creation, Carrom Clash, is a testament to our commitment to quality and fun. Carrom Clash brings the traditional carrom board game into the digital realm, offering an immersive and interactive experience that can be enjoyed by players of all ages. With state-of-the-art graphics, intuitive gameplay, and a robust online community, Carrom Clash is more than just a game—it's a new way to connect, compete, and enjoy with friends and family across the globe.
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Our Philosophy
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            At PixelCraft, we are more than just game developers; we are innovators and storytellers. We strive to craft experiences that resonate with our players, creating memorable moments that last a lifetime. Our games are designed with the player in mind, blending engaging narratives with breathtaking visuals and responsive gameplay.
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Our Promise
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            As we continue to grow and evolve, our commitment to our players remains steadfast. We promise to keep pushing the boundaries of what is possible in gaming, delivering new and exciting content that meets the high standards our players deserve. Whether you are playing Carrom Clash or any future titles from PixelCraft, you can always expect quality, integrity, and innovation.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            Join us on this incredible journey at PixelCraft. Experience the joy of gaming like never truly before.
        </Text>
    </Box>
  )
}

export default AboutContent