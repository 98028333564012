import React from "react";
import { ChakraProvider } from '@chakra-ui/react';
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Contact from "./pages/Contact";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsAndCondition from "./pages/TermsAndCondition";
import Feature from "./components/Feature";
import About from "./pages/About";

function App() {
  return (
      <ChakraProvider>
        <Router>
          <Routes>

              <Route path='/' exact 
              element={<Home />} 
              />  
              <Route path='/#Feature' exact 
              element={<Home />} 
              />
              <Route path='/About' exact 
              element={<About />} 
              />
              <Route path='/Contact' exact 
              element={<Contact />} 
              />
              <Route path='/PrivacyPolicy' exact 
              element={<PrivacyPolicyPage />} 
              /> 
              <Route path='/TermsCondition' exact 
              element={<TermsAndCondition />} 
              /> 
          </Routes>
        </Router>
      </ChakraProvider>
  );
}

export default App;
