import { useState, useEffect, useRef } from 'react';
import 
{ 
Box,
Heading,
Flex,
Image,
Stack
 } from '@chakra-ui/react'
import React from 'react'
import ss1 from '../assets/images/featureImg/ss1.jpg'
import ss2 from '../assets/images/featureImg/ss2.jpg'
import ss3 from '../assets/images/featureImg/ss3.jpg'
import ss4 from '../assets/images/featureImg/ss4.jpg'
import ss5 from '../assets/images/featureImg/ss5.jpg'
import ss6 from '../assets/images/featureImg/ss6.jpg'
import ss7 from '../assets/images/visualsImg/ss7.jpg'
import ss8 from '../assets/images/visualsImg/ss8.jpg'
import ss9 from '../assets/images/visualsImg/ss9.jpg'
import ss10 from '../assets/images/visualsImg/ss10.jpg'
import ss11 from '../assets/images/visualsImg/ss11.jpg'
import ss12 from '../assets/images/visualsImg/ss12.jpg'
import ss13 from '../assets/images/visualsImg/ss13.jpg'
import ss14 from '../assets/images/visualsImg/ss14.jpg'
// import ss15 from '../assets/images/visualsImg/ss15.jpg'
// import ss16 from '../assets/images/visualsImg/ss16.jpg'

const Feature = () => {
  const scrollContainerRef = useRef(null);
  const [scrollInterval, setScrollInterval] = useState(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;
    let scrollDirection = 6; // 1 for forward, -1 for backward

    const autoScroll = () => {
      scrollAmount += scrollDirection;

      // Reverse direction if the end or start is reached
      if (scrollAmount >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
        scrollDirection = -2;
      } else if (scrollAmount <= 0) {
        scrollDirection = 6;
      }

      scrollContainer.scrollLeft = scrollAmount;
    };

    const interval = setInterval(autoScroll, 1); // Adjust the interval for scroll speed
    setScrollInterval(interval);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const stopScroll = () => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  };
  const startScroll = () => {
    if (!scrollInterval) {
      const scrollContainer = scrollContainerRef.current;
      let scrollAmount = scrollContainer.scrollLeft;
      let scrollDirection = 1; // 1 for forward, -1 for backward

      const autoScroll = () => {
        scrollAmount += scrollDirection;

        // Reverse direction if the end or start is reached
        if (scrollAmount >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
          scrollDirection = -1;
        } else if (scrollAmount <= 0) {
          scrollDirection = 1;
        }

        scrollContainer.scrollLeft = scrollAmount;
      };

      const interval = setInterval(autoScroll, 1); // Adjust the interval for scroll speed
      setScrollInterval(interval);
    }
  };

  return (
    <Flex w='90%' m={'auto'} direction={'column'} alignItems='center'>
        <Heading
            fontSize={{
                base: '2xl',
                md: '4xl'
            }}
            textAlign='center'
            mb={'20px'}
            ml={'10px'}
            color="#7d6b57"
            fontWeight={600}
        >
            Carrom Clash Visuals
        </Heading>
            <Stack direction={'row'} overflow={'auto'} ref={scrollContainerRef} onMouseEnter={stopScroll} onClick={stopScroll}  onTouchStart={stopScroll}>
                {/* <Box className="imageStack" display={'flex'}> */}
                    <Image w={"auto"} h={'600px'} src={ss1} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss7} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss8} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss9} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss10} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss11} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss12} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss13} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss14} alt='Dan Abramov' />
                    {/* <Image w={"auto"} h={'600px'} src={ss15} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss16} alt='Dan Abramov' /> */}
                    <Image w={"auto"} h={'600px'} src={ss2} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss3} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss4} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss5} alt='Dan Abramov' />
                    <Image w={"auto"} h={'600px'} src={ss6} alt='Dan Abramov' />
                {/* </Box> */}
            </Stack>
  
    </Flex>
  )
}

export default Feature;