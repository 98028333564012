import React from 'react'
import Layout from '../components/Layout'
import TandC from '../components/TandC'

const TermsAndCondition = () => {
  return (
    <Layout>
        <TandC />
    </Layout>
  )
}

export default TermsAndCondition