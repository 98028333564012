import React from 'react'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
const Contact = () => {
  return (
    <Layout>
        <ContactForm />
    </Layout>
  )
}

export default Contact