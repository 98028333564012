import React from 'react'
import Layout from '../components/Layout'
import PrivacyPolicy from '../components/PrivacyPolicy'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
        <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPolicyPage