import {
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';
import React from 'react'

const TandC = () => {
  return (
    <Box w={'95vw'} m={'auto'}>
        <Heading
            fontSize={{
                base: '4xl',
                md: '5xl'
            }}
            textAlign='center'
            mb={'30px'}
            mt={'20px'}
          
            color="#7d6b57"
        >
            Terms & Conditions
        </Heading>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Introduction
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            These Terms and Conditions ("Terms") govern your access to and use of Carrom Clash, a mobile game developed by Pixcel Craft ("we", "us", "our"). By downloading, accessing, or playing our game, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you must not use or access Carrom Clash.
          </Text>
          <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Game Access and Account Use
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Eligibility:</b> You must be at least 13 years old to play Carrom Clash. Users under 18 require parental or guardian consent.
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Account Creation:</b> You may create an account using your Google account or as a guest. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Prohibited Actions:</b> You are prohibited from using the game for any unlawful purpose, cheating, or exploiting software bugs.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Intellectual Property Rights
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            All intellectual property rights in and to the game, including but not limited to text, graphics, user interface, visual interface, and music, are owned or licensed by us. No rights are granted to you except as expressly set forth in these Terms.
          </Text>
          <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            User Content
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Ownership and License:</b> You retain all rights in and to the content you create or upload, such as text, images, or video. However, by creating or uploading content, you grant us a non-exclusive, transferable, sublicensable, royalty-free, global license to use this content.
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            <b>Acceptable Use:</b>  You agree not to upload or transmit any content that is offensive, obscene, or violates any law or rights of any party.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Purchases and Payments
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>In-game Purchases:</b> Carrom Clash may include in-game features that can be purchased with real money. You agree to pay all fees and applicable taxes incurred by you or anyone using an account registered to you.
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            <b>Refunds:</b> Purchases made in the game are generally non-refundable, except as required by law.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Updates and Termination
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Updates:</b> We may update the game and these Terms from time to time. Your continued use of the game after any such updates constitutes your acceptance of the new Terms.
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            <b>Termination:</b> We may terminate or suspend your access to the game at any time, without prior notice or liability, for any reason, including if you breach these Terms.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Disclaimers and Limitation of Liability
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Disclaimers:</b> Carrom Clash is provided "as is" and "as available" without any warranties, express or implied.
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            <b>Limitation of Liability:</b> To the fullest extent permitted by law, in no event will we be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of or inability to use the game.
            
        </Text>
        <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Governing Law
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Pixcel Craft is based, without giving effect to any principles of conflicts of law.
          </Text>
          <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Dispute Resolution
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            Any disputes arising out of or related to these Terms or Carrom Clash will be resolved through binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify.
          </Text>
          <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            General Terms
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'20px'}
          >
            <b>Severability:</b> If any provision of these Terms is held to be unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from these Terms and will not affect the validity and enforceability of any remaining provisions.
            
        </Text>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            <b>Assignment:</b> These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction.
            
        </Text>
          <Heading
            fontSize={{
                base: '1xl',
                md: '2xl'
            }}
            textAlign='left'
            mb={'20px'}
            ml={'10px'}
        >
            Contact Information
        </Heading>
        <Text 
            align="justify" 
            fontSize={{ base: "1xl", sm: "1xl", lg: "1xl" }}
            ml={'20px'}
            mb={'50px'}
          >
            For any questions or concerns regarding these Terms, please contact us at <a href='mailto:carromclash.helpdesk@gmail.com' style={{color:'blue'}}>carromclash.helpdesk@gmail.com</a>.
          </Text>
    </Box>
  )
}

export default TandC